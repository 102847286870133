import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import Layout from '../../components/Layout';
import Grid from '../../components/Grid';
import Typography from '../../components/Typography';
import translations from './translations';

const Content = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet title={intl.formatMessage(translations.documentTitle)} />
      <Grid container className="pt-120 pb-144">
        <Typography variant="h1" className="mb-12">
          <FormattedMessage {...translations.title} />
        </Typography>
        <Typography variant="subtitle-1" className="mb-40">
          <FormattedMessage {...translations.subtitle} />
        </Typography>
        <Typography variant="h5">
          <FormattedMessage {...translations.helpTitle} />
        </Typography>
        <Typography variant="body-large" color="gray-700" className="mb-40">
          <FormattedMessage
            {...translations.helpTextContent}
            values={{
              link: (
                <Link to={intl.formatMessage(translations.helpTextLinkUrl)} className="hover-underline">
                  <FormattedMessage {...translations.helpTextLinkLabel} />
                </Link>
              ),
            }}
          />
        </Typography>
        <Typography
          variant="body-large"
          component={Link}
          to={intl.formatMessage(translations.homepageLinkUrl)}
          className="hover-underline"
        >
          <FormattedMessage {...translations.homepageLinkLabel} />
        </Typography>
      </Grid>
    </>
  );
};

const NotFound = ({ location }) => (
  <Layout location={location} locale={location.pathname.startsWith('/nl/') ? 'nl-NL' : 'en'}>
    <Content />
  </Layout>
);

NotFound.propTypes = {
  location: PropTypes.object,
};

NotFound.defaultProps = {
  location: {},
};

export default NotFound;
