import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'site.pages.404Page.title',
    defaultMessage: 'Page not found',
    description: 'title of the 404 page',
  },
  documentTitle: {
    id: 'site.pages.404Page.documentTitle',
    defaultMessage: 'Page not found | Afosto',
    description: 'document title of the 404 page',
  },
  subtitle: {
    id: 'site.pages.404Page.subtitle',
    defaultMessage: "This page has been moved or doesn't exist. Use the menu to continue.",
    description: 'subtitle of the 404 page',
  },
  helpTitle: {
    id: 'site.pages.404Page.helpNeeded.title',
    defaultMessage: 'Need help?',
    description: 'title of the help needed section of the 404 page',
  },
  helpTextContent: {
    id: 'site.pages.404Page.helpNeeded.text.content',
    defaultMessage: 'Fill in our {link} and we will contact you as soon as possible.',
    description: 'content of the help needed section of the 404 page',
  },
  helpTextLinkLabel: {
    id: 'site.pages.404Page.helpNeeded.text.link.label',
    defaultMessage: 'contact form',
    description: 'label of the link in the text of the help needed section of the 404 page',
  },
  helpTextLinkUrl: {
    id: 'site.pages.404Page.helpNeeded.text.link.url',
    defaultMessage: '/contact/',
    description: 'url of the link in the text of the help needed section of the 404 page',
  },
  homepageLinkLabel: {
    id: 'site.pages.404Page.homepageLink.label',
    defaultMessage: 'Afosto homepage',
    description: 'label of the homepage link of the 404 page',
  },
  homepageLinkUrl: {
    id: 'site.pages.404Page.homepageLink.url',
    defaultMessage: '/',
    description: 'url of the homepage link of the 404 page',
  },
});
